import { observer } from 'mobx-react';
import { Flex, Loader, Table, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useInventoryStore } from './InventoryStore';
import { DateTime } from 'luxon';

export const List = observer(() => {
  const store = useInventoryStore();
  const items = store.inventoryData?.length ?? 0;
  const { t } = useTranslation('inventory');

  if (store.isLoading) return <Loader />;

  return (
    <Flex gap="8px" direction="column">
      <Text fw="600" lh="25.2px" size="21px" c="#3D7F53" lts="-0.5px">
        {items} {items > 1 || items === 0 ? t('Results') : t('Result')}
      </Text>

      <Table stickyHeader highlightOnHover highlightOnHoverColor="#f6f5ef">
        <Table.Thead bg="#f6f5ef" fw="500" lh="24px">
          <Table.Tr>
            <Table.Td>{t('Table.Columns.Product')}</Table.Td>
            <Table.Td>{t('Table.Columns.Charge')}</Table.Td>
            <Table.Td>{t('Table.Columns.THCAmount')}</Table.Td>
            <Table.Td>{t('Table.Columns.ExpirationDate')}</Table.Td>
            <Table.Td>{t('Table.Columns.Quantity')}</Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {store.inventoryData.map((item) => {
            return (
              <Table.Tr key={item.productId}>
                <Table.Td>{item.productName}</Table.Td>
                <Table.Td>
                  {item.batchId !== null ? item.batchId : '-'}
                </Table.Td>
                <Table.Td>
                  {item.thcTotalInGrams !== null
                    ? `${item.thcTotalInGrams}g THC`
                    : `-`}
                </Table.Td>
                <Table.Td>
                  {item.expiryDate !== null
                    ? DateTime.fromJSDate(item.expiryDate).toFormat(
                        'yyyy-MM-dd',
                      )
                    : `-`}
                </Table.Td>
                <Table.Td>{item.amount}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      {!items && (
        <Text fw="500" lh="24px" size="16px" ta="center" p="40px 0px">
          {t('Table.NoData')}
        </Text>
      )}
    </Flex>
  );
});
