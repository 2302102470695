import {
  PeriodType,
  PointOfSalesType,
  TimeOption,
  GroupBy,
  SellerType,
} from '@web/common';
import type { InventoryQueryReqDto, InventoryResDto } from '@web/common';
import { setupMobX } from '../../../../util/setupMobX';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { inventoryData } from '../../../../request/authenticated-requests/inventory';
import { showErrorNotification } from '../../../../component/notification';

import { DateTime } from 'luxon';

export class InventoryStore {
  @observable public inventoryData: InventoryResDto = [];

  // filters
  @observable public timeOption: TimeOption = TimeOption.Current;
  @observable public groupByOption: GroupBy = GroupBy.Batch;
  @observable public filterOutZeros: boolean = true;
  @observable public selectedDate: Date | null = null; // day
  @observable public selectedMonth: Date | null = null; // monthy
  @observable public selectedYear: Date | null = null; // year
  @observable public dispensary: string | null = PointOfSalesType.All;

  @observable public error: string | null = null;
  @observable public queryFilter: InventoryQueryReqDto = {
    filterOutZeros: true, // the default is to filter out zeros
  };

  @observable public isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action public resetValues = () => {
    this.selectedDate = null;
    this.selectedMonth = null;
    this.selectedYear = null;
    this.dispensary = PointOfSalesType.All;
    this.timeOption = TimeOption.Current;
    this.groupByOption = GroupBy.Batch;
    this.filterOutZeros = true;

    this.filter({
      period: { type: PeriodType.All },
      pointOfSales: { type: PointOfSalesType.All },
      groupBy: GroupBy.Batch,
      filterOutZeros: true,
    });
  };

  @action public setFilterOutZeros = (value: boolean) => {
    this.filterOutZeros = value;

    return this.filter({
      ...this.queryFilter,
      filterOutZeros: value,
    });
  };

  @action public changeTimeOption = (option: TimeOption) => {
    this.timeOption = option;

    if (option === TimeOption.Current) {
      this.selectedDate = null;
      this.selectedMonth = null;
      this.selectedYear = null;

      return this.filter({
        ...this.queryFilter,
        period: { type: PeriodType.All },
      });
    }
  };

  @action public changeGroupByOption = (option: GroupBy) => {
    this.groupByOption = option;

    return this.filter({
      ...this.queryFilter,
      groupBy: option,
    });
  };

  @action public changeDispensary = (option: string | null) => {
    this.dispensary = option;

    if (option === null || option === PointOfSalesType.All)
      return this.filter({
        ...this.queryFilter,
        pointOfSales: { type: PointOfSalesType.All },
      });

    if (option === SellerType.Pharmacy || option === SellerType.SpecialistShop)
      return this.filter({
        ...this.queryFilter,
        pointOfSales: {
          type: PointOfSalesType.Seller,
          which: option,
        },
      });

    return this.filter({
      ...this.queryFilter,
      pointOfSales: {
        type: PointOfSalesType.Gln,
        gln: option,
      },
    });
  };

  @action public handleDateFilter = (value: Date | null) => {
    this.selectedMonth = value;

    if (value === null) {
      return this.filter({
        ...this.queryFilter,
        period: { type: PeriodType.All },
      });
    }

    // we get end of month since we want to get the inventory state of the last day of the month
    const convertedDate = DateTime.fromJSDate(value).endOf('month');
    if (this.timeOption === TimeOption.Month && convertedDate.isValid) {
      return this.filter({
        ...this.queryFilter,
        period: {
          type: PeriodType.Day,
          day: convertedDate,
        },
      });
    }

    return this.filter({
      ...this.queryFilter,
      period: { type: PeriodType.All },
    });
  };

  @action public loadInventory = () => {
    this.isLoading = true;

    inventoryData(this.queryFilter)
      .then((data) => runInAction(() => (this.inventoryData = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public filter(query: Partial<InventoryQueryReqDto>) {
    this.isLoading = true;
    this.queryFilter = {
      ...this.queryFilter,
      ...query,
    };

    this.loadInventory();
  }
}

const { provider, useStore } = setupMobX<InventoryStore>();
export const useInventoryStore = useStore;
export const InventoryStoreProvider = provider;
