import { RaffleTime } from '@web/common';
import { Text } from '@mantine/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export const RaffleDateInfo = ({
  time,
  startDate,
  endDate,
}: {
  time: RaffleTime;
  startDate: DateTime;
  endDate: DateTime;
}) => {
  const { t } = useTranslation('raffle');
  const formattedStartDate = startDate.toFormat('dd.MM.yyyy');
  const formattedEndDate = endDate.toFormat('dd.MM.yyyy');

  if (time === RaffleTime.Active) {
    return (
      <Text c="#4A4A48" size="14px" lh="21px">
        {t('DateInfo.ParticipateUntil')}: {formattedEndDate}
      </Text>
    );
  }

  return (
    <div>
      <Text c="#4A4A48" size="14px" lh="21px">
        {t('DateInfo.ActionPeriod')}:
      </Text>
      <Text c="#4A4A48" size="14px" lh="21px">
        {formattedStartDate} - {formattedEndDate}
      </Text>
    </div>
  );
};
