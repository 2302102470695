import { z } from 'zod';
import { dateTimeTransformer } from '../../../lib/util/date-time-transformer';

export enum PointOfSalesType {
  All = 'all',
  Gln = 'gln',
  Glns = 'glns',
  Seller = 'seller',
}

export enum SellerType {
  Pharmacy = 'pharmacy',
  SpecialistShop = 'specialistShop',
}

export enum PeriodType {
  Range = 'range',
  Day = 'day',
  All = 'all',
}

const pointOfSalesQuery = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PointOfSalesType.All),
  }),
  z.object({
    type: z.literal(PointOfSalesType.Gln),
    gln: z.string(),
  }),
  z.object({
    type: z.literal(PointOfSalesType.Glns),
    glns: z.array(z.string()),
  }),
  z.object({
    type: z.literal(PointOfSalesType.Seller),
    which: z.union([
      z.literal(SellerType.Pharmacy),
      z.literal(SellerType.SpecialistShop),
    ]),
  }),
]);

const periodQuery = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PeriodType.Range),
    from: z.string().transform(dateTimeTransformer()),
    to: z.string().transform(dateTimeTransformer()),
  }),
  z.object({
    type: z.literal(PeriodType.Day),
    day: z.string().transform(dateTimeTransformer()),
  }),
  z.object({
    type: z.literal(PeriodType.All),
  }),
]);

export enum GroupBy {
  Product = 'product',
  Batch = 'batch',
}

export const inventoryQueryReqDto = z
  .object({
    pointOfSales: pointOfSalesQuery,
    period: periodQuery,
    groupBy: z.nativeEnum(GroupBy),
    filterOutZeros: z.boolean(),
  })
  .partial();
export type InventoryQueryReqDto = z.infer<typeof inventoryQueryReqDto>;
