import { useState, useEffect } from 'react';

// TODO: https://quickbird.atlassian.net/browse/SCC-635 improve styling
export const useWindowSize = (width = 1000) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < width);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < width);
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  return isSmallScreen;
};
