import type {
  InventoryQueryReqDto,
  RafflesResDto,
  RaffleWinnersResDto,
} from '@web/common';
import { setupMobX } from '../../../../util/setupMobX';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  activeRaffleData,
  futureRaffleData,
  pastRaffleData,
  winnerData,
} from '../../../../request/authenticated-requests/raffle';
import { showErrorNotification } from '../../../../component/notification';

export class RaffleStore {
  @observable public activeRaffles: RafflesResDto = [];
  @observable public upcomingRaffles: RafflesResDto = [];
  @observable public pastRaffles: RafflesResDto = [];
  @observable public winnerData: RaffleWinnersResDto = [];

  @observable public error: string | null = null;
  @observable public queryFilter: InventoryQueryReqDto = {};

  @observable public isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action public loadActiveRaffles = () => {
    this.isLoading = true;

    activeRaffleData()
      .then((data) => runInAction(() => (this.activeRaffles = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public loadFutureRaffleData = () => {
    this.isLoading = true;

    futureRaffleData()
      .then((data) => runInAction(() => (this.upcomingRaffles = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public loadPastRaffleData = () => {
    this.isLoading = true;

    pastRaffleData()
      .then((data) => runInAction(() => (this.pastRaffles = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public loadWinnerData = () => {
    this.isLoading = true;

    winnerData()
      .then((data) => runInAction(() => (this.winnerData = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };
}

const { provider, useStore } = setupMobX<RaffleStore>();
export const useRaffleStore = useStore;
export const RaffleStoreProvider = provider;
