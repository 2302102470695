import { Space, Flex, Text, Grid } from '@mantine/core';
import { InteractiveText } from '../../../../../../component/InteractiveText';
import { Routes } from '../../../../../../route/Routes';
import { useWindowSize } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { RaffleItem } from '../raffle-item';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useRaffleStore } from '../../RaffleStore';
import { RaffleTime } from '@web/common';

export const UpcomingRaffles = observer(
  ({
    limit = 3,
    hideHeader = false,
    grow = false,
  }: {
    limit?: number;
    hideHeader?: boolean;
    grow?: boolean;
  }) => {
    const isSmallScreen = useWindowSize(600);
    const { t } = useTranslation('raffle');
    const store = useRaffleStore();

    useEffect(() => {
      store.loadFutureRaffleData();
    }, [store]);

    return (
      <>
        {!hideHeader && (
          <>
            <Space h="20px" />
            <Flex justify="space-between">
              <Text size="21px" lh="24px" fw="600">
                {t('UpcomingRaffles')}
              </Text>
              {!isSmallScreen && (
                <InteractiveText
                  text={t('ShowAll')}
                  navigateTo={`${Routes.Study}/${Routes.Raffles}`}
                />
              )}
            </Flex>
          </>
        )}
        <Grid grow={grow}>
          {store.upcomingRaffles?.slice(0, limit).map((raffle) => (
            <Grid.Col span={{ base: 12, md: 6 }} key={raffle.sys.id}>
              <RaffleItem data={raffle} time={RaffleTime.Future} />
            </Grid.Col>
          ))}
        </Grid>

        {!hideHeader && isSmallScreen && (
          <InteractiveText
            text={t('ShowAll')}
            navigateTo={`${Routes.Study}/${Routes.Raffles}`}
          />
        )}
      </>
    );
  },
);
