import { z } from 'zod';
import { contentfulRaffleSchema } from '../contentful';

const raffleWinnerSchema = z.object({
  raffle: contentfulRaffleSchema,
  winner: z.object({
    userSearchID: z.string(),
    raffleId: z.string().uuid(),
    createdAt: z.coerce.date(),
  }),
});
export const raffleWinnersResDto = z.array(raffleWinnerSchema);
export type RaffleWinnersResDto = z.infer<typeof raffleWinnersResDto>;
