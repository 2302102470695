import { Flex, Text } from '@mantine/core';
import { StudyDashboardDetailPage } from '../../../../component/page';
import { useTranslation } from 'react-i18next';
import { LoyalityPointInfo } from '../home-screen/components';
import { useWindowSize } from '../../../../hooks';
import { RaffleStore, RaffleStoreProvider } from './RaffleStore';
import { observer } from 'mobx-react';
import {
  ActiveRaffles,
  UpcomingRaffles,
  PastRaffles,
  // Winners,
} from './components';

const RafflesScreenContent = observer(() => {
  const { t } = useTranslation('raffle');
  // const { t: tEvents } = useTranslation('raffle'); // TODO: https://quickbird.atlassian.net/browse/SCC-411 - Replace with correct translation key
  const isSmallScreen = useWindowSize(600);

  return (
    <StudyDashboardDetailPage breadcrumbText={`/ ${t('BreadcrumbText')} `}>
      <Flex direction="column" gap="0.5em" style={{ width: '100%' }}>
        <Flex
          gap={20}
          justify={isSmallScreen ? 'flex-start' : 'space-between'}
          direction={isSmallScreen ? 'column' : 'row'}
        >
          <Text fw="600" size="28px" lh="38px">
            {t('Verlosungen')}
          </Text>
          <LoyalityPointInfo />
        </Flex>

        <ActiveRaffles />
        <UpcomingRaffles />
        <PastRaffles />
        {/* <Winners /> */}
      </Flex>
    </StudyDashboardDetailPage>
  );
});

export const RafflesScreen = () => {
  return (
    <RaffleStoreProvider store={new RaffleStore()}>
      <RafflesScreenContent />
    </RaffleStoreProvider>
  );
};
