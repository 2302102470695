import { Space, Flex, Text, Grid } from '@mantine/core';
import { InteractiveText } from '../../../../../../component/InteractiveText';
import { Routes } from '../../../../../../route/Routes';
import { useWindowSize } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { RaffleItem } from '../raffle-item';
import { observer } from 'mobx-react';
import { useRaffleStore } from '../../RaffleStore';
import { useEffect } from 'react';
import { RaffleTime } from '@web/common';

export const PastRaffles = observer(() => {
  const isSmallScreen = useWindowSize(600);
  const { t } = useTranslation('raffle');
  const store = useRaffleStore();

  useEffect(() => {
    store.loadPastRaffleData();
  }, [store]);

  return (
    <>
      <Space h="20px" />
      <Flex justify="space-between">
        <Text size="21px" lh="24px" fw="600">
          {t('PastRaffles')}
        </Text>
        {!isSmallScreen && (
          <InteractiveText
            text={t('ShowAll')}
            navigateTo={`${Routes.Study}/${Routes.Raffles}`}
          />
        )}
      </Flex>
      <Grid>
        {store.pastRaffles?.slice(0, 3).map((raffle) => (
          <Grid.Col span={{ base: 12, md: 6 }} key={raffle.sys.id}>
            <RaffleItem data={raffle} time={RaffleTime.Past} />
          </Grid.Col>
        ))}
      </Grid>

      {isSmallScreen && (
        <InteractiveText
          text={t('ShowAll')}
          navigateTo={`${Routes.Study}/${Routes.Raffles}`}
        />
      )}
    </>
  );
});
