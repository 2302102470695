import { z } from 'zod';
import { dateTimeTransformer } from '../../lib';

export const CONTENTFUL_ENGLISH_KEY = 'en-US';
export const CONTENTFUL_SWISS_GERMAN_KEY = 'gsw-CH';

const contentData = z
  .object({
    nodeType: z.string(),
    data: z.object({}),
    content: z.array(
      z
        .object({
          nodeType: z.string(),
          data: z.object({}).passthrough(),
          content: z.array(z.object({}).passthrough()), // if we know the details, we can improve this here
        })
        .passthrough(),
    ),
  })
  .passthrough();

const dateTime = z.union([
  z
    .string() // 2024-04-28T01:00+02:00 (getting it from contentful results in this)
    .transform(
      dateTimeTransformer(`yyyy-MM-dd'T'HH:mmZZ`, { overwriteZone: true }),
    ),
  z
    .string() // 2024-04-28T01:00:00.000+02:00 (getting it from redis results in this)
    .transform(
      dateTimeTransformer(`yyyy-MM-dd'T'HH:mm:ss.SSSZZ`, {
        overwriteZone: true,
      }),
    ),
]);

export const contentfulRaffleSchema = z.object({
  metadata: z.any(), // we can improve at some point later
  sys: z
    .object({
      id: z.string(),
    })
    .passthrough(), // we can improve at some point later

  fields: z
    .object({
      title: z.object({
        [CONTENTFUL_ENGLISH_KEY]: z.string(),
        [CONTENTFUL_SWISS_GERMAN_KEY]: z.string(),
      }),
      content: z.object({
        [CONTENTFUL_ENGLISH_KEY]: contentData,
        [CONTENTFUL_SWISS_GERMAN_KEY]: contentData,
      }),
      startDate: z.object({
        [CONTENTFUL_ENGLISH_KEY]: dateTime,
      }),
      endDate: z.object({
        [CONTENTFUL_ENGLISH_KEY]: dateTime,
      }),
      prizeImage: z
        .object({
          [CONTENTFUL_ENGLISH_KEY]: z.object({
            fields: z.object({
              file: z.object({
                [CONTENTFUL_ENGLISH_KEY]: z.object({
                  url: z.string(),
                }),
              }),
            }),
          }),
        })
        .passthrough(),
      cohort: z
        .object({
          [CONTENTFUL_ENGLISH_KEY]: z
            .string()
            .regex(/^Kohorte\s(100|[1-9][0-9]?)$/),
        })
        .passthrough(),
    })
    .passthrough(),
});
export type ContentfulRaffleSchema = z.infer<typeof contentfulRaffleSchema>;

export const contentfulRaffleResDto = z.array(contentfulRaffleSchema);
export type ContentfulRaffleResDto = z.infer<typeof contentfulRaffleResDto>;
