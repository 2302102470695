import { z } from 'zod';
import { dataMetaSchema } from './data-meta.res.dto';
import { SellerType } from './inventory';

const dispensarySchema = z.object({
  id: z.number(),
  name: z.string(),
  gln: z.string(),
  street: z.string(),
  zip_code: z.string(),
  city: z.string(),
  status: z.string(),
  type: z.union([
    z.literal(SellerType.SpecialistShop),
    z.literal(SellerType.Pharmacy),
  ]),
});
export type DispensaryResDto = z.infer<typeof dispensarySchema>;
export const dispensaryResDto = dispensarySchema;
export const dispensariesMetaSchemaResDto = dataMetaSchema(dispensarySchema);

export const dispensariesWithCountResDto = z.array(
  dispensarySchema.extend({
    associatedUserCount: z.number(),
  }),
);
export type DispensariesResDto = z.infer<typeof dispensariesWithCountResDto>;
