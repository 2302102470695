import { Flex, Image, Text } from '@mantine/core';
import { Card } from '../../../../../../component';
import {
  RaffleItemResDto,
  CONTENTFUL_ENGLISH_KEY,
  CONTENTFUL_SWISS_GERMAN_KEY,
  RaffleTime,
} from '@web/common';
import { useWindowSize } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { Language } from '@prisma/client';
import { RaffleStatusText } from './RaffleStatusText';
import { RaffleDateInfo } from './RaffleDateInfo';

export const RaffleItem = ({
  data,
  time,
}: {
  data: RaffleItemResDto;
  time: RaffleTime;
}) => {
  const { i18n } = useTranslation();
  const translationKey =
    i18n.language === Language.de
      ? CONTENTFUL_SWISS_GERMAN_KEY
      : CONTENTFUL_ENGLISH_KEY;
  const title = data.fields?.title[translationKey];

  const startDate = data.fields?.startDate[CONTENTFUL_ENGLISH_KEY]; // date only has en
  const endDate = data.fields?.endDate[CONTENTFUL_ENGLISH_KEY]; // date only has en
  const imageUrl =
    data.fields?.prizeImage[CONTENTFUL_ENGLISH_KEY].fields.file[ // img only has en
      CONTENTFUL_ENGLISH_KEY
    ].url;
  const status = data.status;
  const isSmallScreen = useWindowSize(600);

  return (
    <Card flexProps={{ p: isSmallScreen ? '18px' : '24px' }}>
      <Flex gap="20px" align="stretch">
        <Flex justify="center" align="center" w={isSmallScreen ? 60 : 80}>
          <Image fit="contain" src={imageUrl} my="0px" radius={5} mah="80px" />
        </Flex>

        <Flex direction="column" justify="space-between">
          <Text fw={600} size="16px" lh="24px">
            {title}
          </Text>

          <RaffleDateInfo time={time} startDate={startDate} endDate={endDate} />
        </Flex>
      </Flex>

      <RaffleStatusText status={status} time={time} />
    </Card>
  );
};
