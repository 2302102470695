import { z } from 'zod';
import { RaffleStatus } from './raffle.status';
import { contentfulRaffleSchema } from '../contentful';

// can be shown on past, active and future
const notParticipating = contentfulRaffleSchema.extend({
  status: z.enum([RaffleStatus.NotParticipating]),
});

// can be shown on active, past and future
const participating = contentfulRaffleSchema.extend({
  status: z.enum([RaffleStatus.Participating]),
  pointsSpent: z.number(),
});

// only for past raffles
const raffleOnGoing = contentfulRaffleSchema.extend({
  status: z.enum([RaffleStatus.RaffleOnGoing]),
  pointsSpent: z.number(),
});
const notWinner = contentfulRaffleSchema.extend({
  status: z.enum([RaffleStatus.NotWinner]),
  pointsSpent: z.number(),
});
const winner = contentfulRaffleSchema.extend({
  status: z.enum([RaffleStatus.Winner]),
  pointsSpent: z.number(),
});

const activeRaffleSchema = z.discriminatedUnion('status', [
  notParticipating,
  participating,
  raffleOnGoing,
  notWinner,
  winner,
]);
export type RaffleItemResDto = z.infer<typeof activeRaffleSchema>;
export const rafflesResDto = z.array(activeRaffleSchema);
export type RafflesResDto = z.infer<typeof rafflesResDto>;
