import { rafflesResDto, raffleWinnersResDto } from '@web/common';
import { authenticatedAPI } from '../AuthenticatedAPI';

export const activeRaffleData = async () => {
  return authenticatedAPI.get(`/api/raffle/active`, rafflesResDto);
};

export const futureRaffleData = async () => {
  return authenticatedAPI.get(`/api/raffle/future`, rafflesResDto);
};

export const pastRaffleData = async () => {
  return authenticatedAPI.get(`/api/raffle/past`, rafflesResDto);
};

export const winnerData = async () => {
  return authenticatedAPI.get(`/api/raffle/winner`, raffleWinnersResDto);
};
