import { observer } from 'mobx-react';
import { EmptyEvents } from '../../../news';
import {
  RaffleStore,
  RaffleStoreProvider,
  useRaffleStore,
} from '../../RaffleStore';
import { ActiveRaffles } from '../active-raffles';
import { UpcomingRaffles } from '../upcoming-raffles';
import { Flex } from '@mantine/core';

const RafflePreviewContent = observer(() => {
  const store = useRaffleStore();
  const hasData = store.activeRaffles || store.upcomingRaffles;

  return hasData ? (
    <Flex direction="column" gap="16px">
      <ActiveRaffles limit={1} hideHeader grow />
      <UpcomingRaffles limit={1} hideHeader grow />
    </Flex>
  ) : (
    <EmptyEvents />
  );
});

export const RafflePreview = () => {
  return (
    <RaffleStoreProvider store={new RaffleStore()}>
      <RafflePreviewContent />
    </RaffleStoreProvider>
  );
};
