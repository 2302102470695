import { Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const EmptyEvents = () => {
  const { t } = useTranslation('homescreen');

  return (
    <Placeholder
      title={t('ContestAndEvents.Placeholder.Title')}
      description={t('ContestAndEvents.Placeholder.Description')}
    />
  );
};

export const EmptyNews = () => {
  const { t } = useTranslation('homescreen');

  return (
    <Placeholder
      title={t('News.Placeholder.Title')}
      description={t('News.Placeholder.Description')}
    />
  );
};

export const Placeholder = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Flex
      direction="column"
      p="20px"
      style={{
        borderRadius: '8px',
        border: 'dashed 2px #D0CDB1',
        borderSpacing: 5,
      }}
    >
      <Text size="16px" lh="24px" fw="700" c="#202020">
        {title}
      </Text>
      <Text c="#202020">{description}</Text>
    </Flex>
  );
};
