import { Grid, Space, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { RaffleItem } from '../raffle-item';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useRaffleStore } from '../../RaffleStore';
import { RaffleTime } from '@web/common';

export const ActiveRaffles = observer(
  ({
    limit = 3,
    hideHeader = false,
    grow = false,
  }: {
    limit?: number;
    hideHeader?: boolean;
    grow?: boolean;
  }) => {
    const { t } = useTranslation('raffle');
    const store = useRaffleStore();

    useEffect(() => {
      store.loadActiveRaffles();
    }, [store]);

    return (
      <>
        {!hideHeader && (
          <>
            <Space h="20px" />
            <Text size="21px" lh="24px" fw="600">
              {t('ActiveRaffles')}
            </Text>
          </>
        )}

        <Grid grow={grow}>
          {store.activeRaffles?.slice(0, limit).map((raffle) => (
            <Grid.Col span={{ base: 12, md: 6 }} key={raffle.sys.id}>
              <RaffleItem
                key={raffle.sys.id}
                data={raffle}
                time={RaffleTime.Active}
              />
            </Grid.Col>
          ))}
        </Grid>
      </>
    );
  },
);
