export enum RaffleStatus {
  NotParticipating = 'NotParticipating',
  Participating = 'Participating',
  RaffleOnGoing = 'RaffleOnGoing',
  Winner = 'Winner',
  NotWinner = 'NotWinner',
}

// depends on which endpoint was called
export enum RaffleTime {
  Active = 'active',
  Past = 'past',
  Future = 'future',
}

// teilnehmen button: state notparticipating <- only show in active raffle list
// Teilgenommen - Aktiv: state participating
// beendet - Auslosung ausstehend: state raffleOnGoing
// beendet - nicht gewonnen: state notWinner
// beendet - gewonnen: state winner
// nicht teilgenommen: state notParticipating

// active list states: notparticipating, participating
// past list states: notparticipating, notWinner, winner, raflleOnGoing
// future list states: notparticipating ( we dont show any hint for that since its the future )
