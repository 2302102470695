import { GroupBy, InventoryResDto } from '@web/common';
import { DateTime } from 'luxon';

export const mapData = (data: InventoryResDto, groupBy: GroupBy) => {
  switch (groupBy) {
    case GroupBy.Product:
      return data.map((item) => {
        return {
          product: item.productName,
          productId: item.productId,
          quantity: item.amount,
        };
      });
    case GroupBy.Batch:
      return data.map((item) => {
        return {
          product: item.productName,
          productId: item.productId,
          charge: item.batchId,
          totalTHC: item.thcTotalInGrams || 'n/a',
          thcPerUnit: item.thcPerUnit || 'n/a',
          expirationDate:
            (item.expiryDate
              ? DateTime.fromJSDate(item.expiryDate).toFormat(`yyyy-MM-dd`)
              : null) || 'n/a',
          quantity: item.amount,
        };
      });
  }
};
